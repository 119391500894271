export function setSameHeightOnItems(items: HTMLElement[]): void {
  const maxHeight = Math.max(...items.map((item: HTMLElement): number => item.offsetHeight));
  items.forEach((item): void => {
    item.style.height = `${maxHeight}px`;
  });
}

export function setSameMinHeightOnItems(items: HTMLElement[]): void {
  const maxHeight = Math.max(...items.map((item: HTMLElement): number => item.offsetHeight));
  items.forEach((item): void => {
    item.style.minHeight = `${maxHeight}px`;
  });
}

export function getHeightDisplayNone(elem: HTMLElement): number {
  const currentDisplay = elem.style.display;
  elem.style.display = "block";
  const height = elem.scrollHeight;
  elem.style.display = currentDisplay;
  return height;
}

export function getMaxHeight(elms: NodeListOf<Element>): number {
  let height = 0;
  elms.forEach((el): void => {
    const elHeight = el.clientHeight;
    if (elHeight > height) {
      height = elHeight;
    }
  });
  return height;
}

export function setHeight(elms: NodeListOf<HTMLElement>, height: number): void {
  elms.forEach((el): void => {
    el.style.height = `${height}px`;
  });
}

export const getOuterWidth = (el: HTMLElement): number => {
  if (!el || el === null) {
    return 0;
  }
  const s = window.getComputedStyle(el as Element, null);
  return (
    el.offsetWidth + parseInt(s.getPropertyValue("margin-left"), 10) + parseInt(s.getPropertyValue("margin-right"), 10)
  );
};

export const getOuterHeight = (el: HTMLElement): number => {
  if (!el || el === null) {
    return 0;
  }
  const s = window.getComputedStyle(el as Element, null);
  return (
    el.offsetHeight + parseInt(s.getPropertyValue("margin-top"), 10) + parseInt(s.getPropertyValue("margin-bottom"), 10)
  );
};
