import { getOuterHeight, getOuterWidth } from "@shared/Utils/size-utils";
import Splide from "@splidejs/splide";

const setFixSizeOnCarouselItems = (items: NodeListOf<HTMLElement>) => {
  let maxWidth = 0;
  let maxHeight = 0;

  items.forEach((item) => {
    const width = getOuterWidth(item);
    if (width > maxWidth) {
      maxWidth = width;
    }
    const height = Math.max(
      getOuterHeight(item),
      getOuterHeight(item.firstElementChild as HTMLElement),
      getOuterHeight(item.firstElementChild?.firstElementChild as HTMLElement),
    );

    if (height > maxHeight) {
      maxHeight = height;
    }
  });

  items.forEach((item) => {
    item.style.width = `${maxWidth / 16}rem`;
    item.style.height = `${maxHeight / 16}rem`;
  });
};

Splide.defaults = {
  type: "loop",
  focus: 0,
  autoWidth: true,
  perPage: 1,
  perMove: 1,
  omitEnd: true,
  breakpoints: {
    770: {
      perPage: 1,
      focus: "center",
    },
  },
};

const elms = document.querySelectorAll<HTMLElement>(".splide");
for (let i = 0; i < elms.length; i++) {
  const el = elms[i];
  const splide = new Splide(el);
  splide.on("pagination:mounted", function (data) {
    // `items` contains all dot items
    data.items.forEach(function (item) {
      item.button.innerHTML = '<svg><use xlink:href="#ice-hexagon"></use></svg>';
    });
  });

  splide.on("mounted", (): void => {
    if (el.dataset.sameSize === "true") {
      const items = el.querySelectorAll<HTMLElement>(".splide__slide");
      setFixSizeOnCarouselItems(items);
    }
  });

  splide.mount();
}
